import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dataTables from "./modules/dataTables";

const store = new Vuex.Store({
	strict: process.env.NODE_ENV === "development",
	modules: { dataTables },
	state: {
		fontScale: +(localStorage.getItem("mapas/fonte")) || 1,
		sideBarWidth: 0
	},
	mutations: {
		updateSideBarWidth (state, w) {
			state.sideBarWidth = w;
		},
		setFontScale (state, scale) {
			if (!+scale) return;
			if (scale > 3) scale = 3;
			if (scale < 1) scale = 1;
			localStorage.setItem("mapas/fonte", scale);
			state.fontScale = scale;
		}
	}
});

export default store;
