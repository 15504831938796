<template>
	<p>Autenticando...</p>
</template>

<script>
	import { AuthService } from "../services/auth";

	export default {
		data () {
			return {
				authService: new AuthService()
			};
		},

		beforeMount () {
			const urlParams = new URLSearchParams(window.location.search);
			const entries = urlParams.entries();
			const params = Object.fromEntries(entries);
			const paramsStr = JSON.stringify(params);
			localStorage.setItem("params", paramsStr);
		},

		mounted () {
			let parametros = localStorage.getItem("params");
			parametros = JSON.parse(parametros);
			this.$router.push({ path:  parametros.pageToBack ? parametros.pageToBack : "/"});
		}
	};
</script>
